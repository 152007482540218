import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../layouts/Layout';
import Seo from '../components/Seo';

import Abstract from '../assets/svg/abstract.svg';
import Artist from '../assets/svg/artist.svg';
import Logo from '../assets/svg/logo.svg';
import PersonalData from '../assets/svg/personal-data.svg';
import Programmer from '../assets/svg/programmer.svg';
import SocialGirl from '../assets/svg/social-girl.svg';
import Train from '../assets/images/train.png';

const About = () => {
  const { t } = useTranslation();
  return (
    <Layout showHeader>
      <Seo title="About" path="/about" />
      <article className="px-4 sm:px-6 lg:px-16 mx-auto max-w-5xl">
        <div className="flex md:flex-row-reverse flex-wrap items-center">
          <div className="p-6 pb-0 md:pb-6 mx-auto w-4/5 md:w-1/2">
            <Programmer
              alt={t('Programmer')}
              className="z-50 mx-auto w-full md:w-1/2 h-auto"
            />
          </div>
          <div className="p-4 md:p-6 w-full sm:w-1/2">
            <h2 className="mb-3 text-3xl font-bold leading-none text-gray-800">
              Hello there, I&rsquo;m Michele!
            </h2>
            <p className="text-xl">I&rsquo;m a technologist and a developer.</p>
          </div>
        </div>

        <div className="flex flex-wrap items-center">
          <div className="p-6 pb-0 md:pb-6 mx-auto w-4/5 md:w-1/2">
            <img
              src={Train}
              alt={t('STEAM Powered Logo')}
              className="z-50 mx-auto w-full md:w-1/2 h-auto rounded-full"
            />
          </div>
          <div className="p-4 md:p-6 w-full sm:w-1/2">
            <h2 className="mb-3 text-3xl font-bold leading-none text-gray-800">
              I&rsquo;m also a content creator.
            </h2>
            <p className="text-xl">
              I have a podcast and YouTube channel called{' '}
              <a
                href="https://steampoweredshow.com"
                target="_blank"
                rel="noreferrer"
              >
                STEAM Powered
              </a>{' '}
              where I have incredible conversations with women in{' '}
              <abbr title="Science, Technology, Engineering, Mathematics, and Medicine">
                STEMM
              </abbr>{' '}
              fields about their journey, their work, their passions, and the
              lessons they learned along the way.
            </p>
            <p className="text-xl">It&rsquo;s the best.</p>
          </div>
        </div>

        <div className="flex md:flex-row-reverse flex-wrap items-center">
          <div className="p-6 pb-0 md:pb-6 mx-auto w-4/5 md:w-1/2">
            <SocialGirl
              alt={t('Social media')}
              className="z-50 mx-auto w-full md:w-3/5 h-auto"
            />
          </div>
          <div className="p-4 md:p-6 w-full sm:w-1/2">
            <h2 className="mb-3 text-3xl font-bold leading-none text-gray-800">
              But social media promotion is pants.
            </h2>
            <p className="text-xl">
              So many platforms, each with different requirements.
            </p>
            <p className="text-xl">
              Automation tools help, but there is still a lot of manual work
              involved. More so when some platforms lack automation support.
            </p>
          </div>
        </div>

        <div className="flex flex-wrap items-center">
          <div className="p-6 pb-0 md:pb-6 mx-auto w-4/5 md:w-1/2">
            <PersonalData
              alt={t('Repetition')}
              className="z-50 mx-auto w-full md:w-3/5 h-auto"
            />
          </div>
          <div className="p-4 md:p-6 w-full sm:w-1/2">
            <h2 className="mb-3 text-3xl font-bold leading-none text-gray-800">
              It&rsquo;s tedious and repetitive.
            </h2>
            <p className="text-xl">
              A lot of my copy is very similar, it just needs to be a slightly
              different shape for each platform.
            </p>
            <p className="text-xl">
              Even with templates and liberal use of search-and-replace,
              it&rsquo;s still a time sink, and as a solo creator with many hats
              to wear, I can&rsquo;t afford that.
            </p>
          </div>
        </div>

        <div className="flex md:flex-row-reverse flex-wrap items-center">
          <div className="p-6 pb-0 md:pb-6 mx-auto w-4/5 md:w-1/2">
            <Logo
              alt={t('Copybara Logo')}
              className="z-50 mx-auto w-full md:w-1/2 h-auto text-oxford-blue"
            />
          </div>
          <div className="p-4 md:p-6 w-full sm:w-1/2">
            <h2 className="mb-3 text-3xl font-bold leading-none text-gray-800">
              Enter Copybara.
            </h2>
            <p className="text-xl">
              I created Copybara to be that bridge between crafting and
              publishing copy.
            </p>
            <p className="text-xl">
              Plug in your values, finesse your templates, and your copy is
              ready to be sent out into the world.
            </p>
          </div>
        </div>

        <div className="flex flex-wrap items-center">
          <div className="p-6 pb-0 md:pb-6 mx-auto w-4/5 md:w-1/2">
            <Abstract
              alt={t('Creative thinking')}
              className="z-50 mx-auto w-full md:w-3/5 h-auto"
            />
          </div>
          <div className="p-4 md:p-6 w-full sm:w-1/2">
            <div className="align-middle">
              <h2 className="mb-3 text-3xl font-bold leading-none text-gray-800">
                It&rsquo;s about finding creative solutions.
              </h2>
              <p className="text-xl">
                There are no silver bullets. Automation tools get us part of the
                way there, but there are still services which are yet to offer
                automation integration. So it&rsquo;ll be about creative ways to
                automate the unautomatable as well. *shakes fist*
              </p>
            </div>
          </div>
        </div>

        <div className="flex md:flex-row-reverse flex-wrap items-center">
          <div className="p-6 pb-0 md:pb-6 mx-auto w-4/5 md:w-1/2">
            <Artist
              alt={t('Artist')}
              className="z-50 mx-auto w-full md:w-3/5 h-auto"
            />
          </div>
          <div className="p-4 md:p-6 w-full sm:w-1/2">
            <h2 className="mb-3 text-3xl font-bold leading-none text-gray-800">
              It&rsquo;s about being creative.
            </h2>
            <p className="text-xl">
              I just want creators and artists to be able to spend more time
              creating and less time on copy admin.
            </p>
          </div>
        </div>

        <hr />

        <p className="text-xs">
          Illustrations by{' '}
          <a href="https://undraw.co/" target="_blank" rel="noreferrer">
            undraw.co
          </a>
          . Copybara by me.
        </p>
      </article>
    </Layout>
  );
};

export default About;
